import React from "react";
import Layout from "../components/layout";
import { Footer } from "../components/Footer/index";
import Member from "../container/Member";

export default () => (
  <Layout>
    <Member />
    <Footer />
  </Layout>
);
