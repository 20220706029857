import React from "react";
import styled from "styled-components";
import { parseCitycode } from "../../core/utils/index";
import Bg from "../../images/member/rsz.png";
const propertyArr = ["国际标准", "国内较高标准", "国内一般标准"];
const detailField = [
  {
    field: "建筑面积",
    value: "constructionArea",
  },
  {
    field: "经营面积",
    value: "operationArea",
  },
  {
    field: "开业时间",
    value: "openingDate",
  },
  {
    field: "合作模式",
    value: "operationMode",
  },
  {
    field: "交易模式",
    value: "transactionMode",
  },
  {
    field: "业主信息",
    value: "ownerInfo",
  },
];

let qs = ["citycode", "adcode", "address", "name", "longtitude", "poiid", "latitude"];
const OSS = require("ali-oss");

const client = new OSS({
  region: "oss-cn-beijing",
  accessKeyId: "LTAI4GCqHJUFpuuDJRN2tweb",
  accessKeySecret: "9aQ0ss8PhT7oecT9N3wnVz0gfUOYQP",
  bucket: "amssdata-freetrial",
  endPoint: "oss-cn-beijing.aliyuncs.com",
});

const TableDetail = (props) => {
  const { data } = props;
  const { citycode, adcode, address, name, longtitude, poiid, latitude, hardware, height, decoration, property, tenant, owner } = data.projectinfo;
  let url = `https://databoard.bridge5.asia/valuation/report/project?citycode=${citycode}&adcode=${adcode}&address=${address}&projectname=${name}&longtitude=${longtitude}&latitude=${latitude}&poiid=${poiid}&id=${data._id}&hardware=${hardware}&height=${height}&decoration=${decoration}&property=${property}&tenant=${tenant}&owner=${owner}`;
  return (
    <Container>
      <p className="return" onClick={() => props.handleShowDetail(null)}>
        返回列表
      </p>
      <h6 className="title">{data._id}</h6>
      <Wrap>
        <Table>
          <tr>
            <TH valign="middle" align="center">
              城市
            </TH>
            <TD>{parseCitycode[data.projectinfo.citycode]}</TD>
          </tr>
          <tr>
            <TH valign="middle" align="center">
              地址
            </TH>
            <TD>{data.projectinfo.address}</TD>
          </tr>
          <tr>
            <TH valign="middle" align="center">
              业态
            </TH>
            <TD>{propertyArr[data.projectinfo.property - 1]}</TD>
          </tr>
          {detailField.map((value) => (
            <tr>
              <TH valign="middle" align="center">
                {value.field}
              </TH>
              <TD>
                {data.projectinfo[value.value]} {(value.value === "constructionArea" || value.value === "operationArea") && "平方米"}
              </TD>
            </tr>
          ))}
        </Table>
        <div style={{ width: "290px", paddingTop: "30px" }}>
          <ItemImage src={Bg} />
          <Button onClick={() => window.open(url)} isDisable={false}>
            <div className="circle" />
            <a>1. 项目大数据分析</a>
          </Button>
          <Button isDisable={true}>
            <div className="circle" />
            2. 一键估值/DCF
          </Button>
          <Button isDisable={true}>
            <div className="circle" />
            3. 业主提供资料PDF
          </Button>
          <Button isDisable={true}>
            <div className="circle" />
            4. 对接业主/投资人
          </Button>
        </div>
      </Wrap>
    </Container>
  );
};

export default TableDetail;

const Container = styled.div`
  padding: 30px;
  overflow: hidden;
  .return,
  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.21;
    letter-spacing: 0.31px;
    color: #333333;
  }
  .title {
    margin-top: 30px;
  }
  .return {
    cursor: pointer;
  }
  table,
  th,
  td {
    border: 1px solid #dadada;
    border-collapse: collapse;
  }
`;

const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Table = styled.table`
  width: 670px;
  margin-top: 30px;
`;

const TH = styled.th`
  width: 120px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.31px;
  color: #999999;
  height: 60px;
  vertical-align: middle;
`;
const TD = styled.td`
  padding-left: 15px;
  font-size: 14px;
  color: #333333;
  vertical-align: middle;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 195px;
  background-color: #999;
`;

const Button = styled.div`
  width: 100%;
  height: 64px;
  border-radius: 33.5px;
  background-color: ${(props) => (props.isDisable ? "#aaa9a9" : "#b50005")};
  display: flex;
  margin-top: 12px;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.56;
  letter-spacing: 0.4px;
  color: #ffffff;
  cursor: pointer;

  a {
    color: #ffffff;
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    border: 1px solid #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
  }
`;
