import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AmssLogoImg from "../../images/amsslogo.png";
import { CommonMember, VipMember } from "./memberType";

const Member = () => {
  const [tab, setTab] = useState("common"); // vip & common

  return (
    <Container>
      <Wrap>
        <AmssLogo src={AmssLogoImg} alt="" />
        <MenuWrap>
          <p className="tips">
            <a href="/membership">如何升级为客户级会员</a>
          </p>
          <Menu isActive={tab === "common"} onClick={() => setTab("common")}>
            AMSS会员
          </Menu>
          <Menu isActive={tab === "vip"} style={{ marginLeft: "25px" }} onClick={() => setTab("vip")}>
            客户级会员专区
          </Menu>
        </MenuWrap>
        {tab === "common" && <CommonMember type={tab} />}
        {tab === "vip" && <VipMember type={tab} />}
      </Wrap>
    </Container>
  );
};

export default Member;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 26px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
`;

const Wrap = styled.div`
  width: 1132px;
  max-width: 1132px;
`;
const AmssLogo = styled.img`
  height: 70px;
  margin: 15px 0;
`;

const MenuWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  margin-top: 25px;
  .tips a {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 28px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: 0.35px;
    color: #b50005;
  }
`;

const Menu = styled.div`
  width: 233px;
  height: 60px;
  border-radius: 10px 10px 0 0;
  background-color: ${(props) => (props.isActive ? "#fff" : "#aaa9a9")};
  color: ${(props) => (props.isActive ? "#b50005" : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.56;
  letter-spacing: 0.4px;
  cursor: pointer;
`;
