export const parseCitycode = {
  "010": "北京",
  "0411": "大连",
  "0531": "济南",
  "0532": "青岛",
  "024": "沈阳",
  "022": "天津",
  "028": "成都",
  "023": "重庆",
  "0592": "厦门",
  "029": "西安",
  "0851": "贵阳",
  "0898": "海口",
  "0871": "昆明",
  "0931": "兰州",
  "027": "武汉",
  "0731": "长沙",
  "0371": "郑州",
  "021": "上海",
  "0571": "杭州",
  "025": "南京",
  "0574": "宁波",
  "0512": "苏州",
  "020": "广州",
  "0755": "深圳",
  "0757": "佛山",
};
